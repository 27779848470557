import {
  MailOutlined,
  TruckOutlined,
  ProductOutlined,
  PhoneOutlined,
  FireOutlined,
} from '@ant-design/icons'
import './App.css'
import { Col, Layout, Row, Space } from 'antd'
import { Content, Footer } from 'antd/es/layout/layout'
import Menu from './components/menu'
import locales from './locales/locale'
import { useState } from 'react'
import { Fade, Slide, Zoom } from 'react-awesome-reveal'
import drinks from './images/drinks.jpg'
import chip from './images/chip.jpg'

function App() {
  const [language, setLanguage] = useState(locales.getLanguage())

  return (
    <Layout>
      <Menu setLang={setLanguage} lang={language} />
      <Content style={{ zIndex: 1 }}>
        <div className="background">
          <Space
            className="primoParagrafo"
            direction="vertical"
            align="start"
            size="small"
          >
            <h2>TIMBALLO TRADE</h2>
            <p>{locales.paragrafo1}</p>
          </Space>
        </div>
        <div className="background1"></div>
        <div className="paragrafo sfondoScuro">
          <div style={{ width: '90%' }}>
            <h1>{locales.paragrafo3.titolo}</h1>
            <p style={{ fontSize: '1.5vw' }}>
              {locales.paragrafo3.descrizione}
            </p>
          </div>
          <Row style={{ textAlign: 'start', width: '90%' }} gutter={[32, 32]}>
            <Col
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
              md={11}
              span={24}
            >
              <Zoom>
                <Row>
                  <Col
                    style={{
                      textAlign: 'center',
                      alignContent: 'center',
                    }}
                    span={2}
                  >
                    <ProductOutlined className="icon-box" />
                  </Col>
                  <Col offset={1} span={21}>
                    <h1>{locales.paragrafo3Bis.riquadro1.titolo}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col offset={3} span={21}>
                    <p style={{ margin: '0%', marginBlockEnd: '1em' }}>
                      {locales.paragrafo3Bis.riquadro1.descrizione}
                    </p>
                  </Col>
                </Row>
              </Zoom>
            </Col>
            <Col md={2} span={0}></Col>
            <Col
              style={{
                width: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
              md={11}
              span={24}
            >
              <Zoom delay={100}>
                <Row>
                  <Col
                    style={{
                      textAlign: 'center',
                      alignContent: 'center',
                    }}
                    span={2}
                  >
                    <PhoneOutlined className="icon-box" />
                  </Col>
                  <Col offset={1} span={21}>
                    <h1>{locales.paragrafo3Bis.riquadro2.titolo}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col offset={3} span={21}>
                    <p style={{ margin: '0%', marginBlockEnd: '1em' }}>
                      {locales.paragrafo3Bis.riquadro2.descrizione}
                    </p>
                  </Col>
                </Row>
              </Zoom>
            </Col>
            <Col
              style={{
                width: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
              md={11}
              span={24}
            >
              <Zoom delay={200}>
                <Row>
                  <Col
                    style={{
                      textAlign: 'center',
                      alignContent: 'center',
                    }}
                    span={2}
                  >
                    <TruckOutlined className="icon-box" />
                  </Col>
                  <Col offset={1} span={21}>
                    <h1>{locales.paragrafo3Bis.riquadro3.titolo}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col offset={3} span={21}>
                    <p style={{ margin: '0%', marginBlockEnd: '1em' }}>
                      {locales.paragrafo3Bis.riquadro3.descrizione}
                    </p>
                  </Col>
                </Row>
              </Zoom>
            </Col>
            <Col md={2} span={0}></Col>
            <Col
              style={{
                width: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
              md={11}
              span={24}
            >
              <Zoom delay={300}>
                <Row>
                  <Col
                    style={{
                      textAlign: 'center',
                      alignContent: 'center',
                    }}
                    span={2}
                  >
                    <FireOutlined className="icon-box" />
                  </Col>
                  <Col offset={1} span={21}>
                    <h1>{locales.paragrafo3Bis.riquadro4.titolo}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col offset={3} span={21}>
                    <p style={{ margin: '0%', marginBlockEnd: '1em' }}>
                      {locales.paragrafo3Bis.riquadro4.descrizione}
                    </p>
                  </Col>
                </Row>
              </Zoom>
            </Col>
          </Row>
        </div>
        <div className="paragrafo" style={{ minHeight: '50vh' }}>
          <div style={{ width: '80%', paddingBottom: 0 }}>
            <Fade>
              <h1>{locales.paragrafo7.titolo}</h1>
              <p>{locales.paragrafo7.descrizione}</p>
            </Fade>
          </div>
        </div>
        <div
          className="paragrafo sfondoScuro"
          style={{
            overflow: 'hidden',
            textAlign: 'start',
          }}
        >
          <Fade>
            <div>
              <h1>{locales.paragrafo5.titolo}</h1>
              <br></br>
              <b>{locales.paragrafo5.titolo1}</b>
              <p>{locales.paragrafo5.descrizione1}</p>
              <b>{locales.paragrafo5.titolo2}</b>
              <p>{locales.paragrafo5.descrizione2}</p>
            </div>
          </Fade>
          <div>
            <Slide direction="right">
              <img
                alt="By Olena Bohovyk"
                style={{
                  borderRadius: '1vw',
                  position: 'relative',
                  top: '5em',
                  left: '10em',
                }}
                width={'50%'}
                src={drinks}
              />
            </Slide>
            <Slide direction="up">
              <img
                className="img-style"
                alt="By Nic Wood"
                width={'50%'}
                src={chip}
              />
            </Slide>
          </div>
        </div>
      </Content>
      <Footer>
        <Row style={{ placeContent: 'center' }}>
          <Col>
            <b>{locales.contact}</b>
          </Col>
        </Row>
        <Row style={{ placeContent: 'center' }}>
          <Col>
            <Space
              style={{
                borderRight: '1px #ffffff3d solid',
                padding: '0 1vw',
              }}
              size={'small'}
            >
              <PhoneOutlined />
              <a href="tel:+400783854503" style={{ color: 'black' }}>
                +400 783854503
              </a>
            </Space>
          </Col>
          <Col>
            <Space
              style={{
                padding: '0 1vw',
              }}
              size={'small'}
            >
              <MailOutlined />
              <a href="mailto:info@timballo.eu" style={{ color: 'black' }}>
                info@timballo.eu
              </a>
            </Space>
          </Col>
        </Row>
        <Row style={{ placeContent: 'center', marginTop: '1vw' }}>
          <Col
            style={{
              padding: '0 1vw',
            }}
          >
            <a
              style={{ color: 'black', fontWeight: 'bold' }}
              target="_blank"
              href="https://www.termsfeed.com/live/3ba394a2-3606-4ca0-9173-f6770dc57d15"
              rel="noreferrer"
            >
              Privacy policy
            </a>
          </Col>
          <Col
            style={{
              padding: '0 1vw',
            }}
          >
            <a
              style={{ color: 'black', fontWeight: 'bold' }}
              target="_blank"
              href="https://www.cookiepolicygenerator.com/live.php?token=p1NeCAbSMSSYxpQEP2PfpFIRGIXXyT2w"
              rel="noreferrer"
            >
              Cookie policy
            </a>
          </Col>
        </Row>
      </Footer>
    </Layout>
  )
}

export default App
