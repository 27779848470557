import LocalizedStrings from 'react-localization'

let locales = new LocalizedStrings({
  en: {
    paragrafo1:
      'Specialising in the buying and selling of soft drinks and electronic products',
    paragrafo3: {
      titolo: 'Our strengths',
      descrizione:
        'Find out what makes us unique in the buying and selling industry.',
    },
    paragrafo3Bis: {
      riquadro1: {
        titolo: 'Wide range of soft drinks',
        descrizione: 'Discover our selection of high-quality soft drinks.',
      },
      riquadro3: {
        titolo: 'Fast and safe delivery',
        descrizione: 'We guarantee fast and safe delivery of your orders.',
      },
      riquadro2: {
        titolo: 'Innovative electronic products',
        descrizione:
          'We offer a wide range of state-of-the-art electronic products',
      },
      riquadro4: {
        titolo: 'Dedicated customer support',
        descrizione: 'Our team is always ready to assist you with any needs',
      },
    },
    paragrafo5: {
      titolo: 'Products',
      titolo1: 'Soft Drinks',
      descrizione1:
        'Discover our wide range of soft drinks, from natural juices to fizzy drinks, all selected for their quality and taste. Each product is chosen to offer freshness and health to our customers.',
      titolo2: 'Electronic Products',
      descrizione2:
        'Explore our catalogue of electronic products, including the latest smartphones, tablets, technology accessories and more. We guarantee high-quality devices that will help you stay connected and on the cutting edge.',
    },

    paragrafo7: {
      titolo: 'About Us',
      descrizione:
        'We are a leading company in the buying and selling of soft drinks and electronic products, offering our customers a wide range of options to meet their every need. Our mission is to provide high quality products accompanied by excellent customer service, always guaranteeing maximum satisfaction.',
    },
    contact: 'Contact us',
  },
  ro: {
    paragrafo1:
      'Specializat în cumpărarea și vânzarea de băuturi răcoritoare și produse electronice',
    paragrafo3: {
      titolo: 'Punctele noastre forte',
      descrizione:
        'Află ce ne face unici în industria de cumpărare și vânzare.',
    },
    paragrafo3Bis: {
      riquadro1: {
        titolo: 'Gama largă de băuturi răcoritoare',
        descrizione:
          'Descoperă selecția noastră de băuturi răcoritoare de înaltă calitate.',
      },
      riquadro3: {
        titolo: 'Livrare rapidă și sigură',
        descrizione: 'Garantăm livrarea rapidă și sigură a comenzilor dvs.',
      },
      riquadro2: {
        titolo: 'Produse electronice inovatoare',
        descrizione:
          'Oferim o gamă largă de produse electronice de ultimă generație',
      },
      riquadro4: {
        titolo: 'Suport pentru clienți dedicat',
        descrizione:
          'Echipa noastră este întotdeauna gata să vă asiste cu orice nevoie',
      },
    },
    paragrafo5: {
      titolo: 'Produse',
      titolo1: 'Băuturi Răcoritoare',
      descrizione1:
        'Descoperă gama noastră largă de băuturi răcoritoare, de la sucuri naturale la băuturi carbogazoase, toate selectate pentru calitatea și gustul lor. Fiecare produs este ales pentru a oferi prospețime și sănătate clienților noștri.',
      titolo2: 'Produse Electronice',
      descrizione2:
        'Explorează catalogul nostru de produse electronice, inclusiv cele mai noi smartphone-uri, tablete, accesorii tehnologice și multe altele. Garantăm dispozitive de înaltă calitate care vă vor ajuta să rămâneți conectat și la curent cu noutățile tehnologice.',
    },
    paragrafo7: {
      titolo: 'Despre Noi',
      descrizione:
        'Suntem o companie de top în cumpărarea și vânzarea de băuturi răcoritoare și produse electronice, oferind clienților noștri o gamă largă de opțiuni pentru a satisface fiecare nevoie. Misiunea noastră este de a furniza produse de înaltă calitate, însoțite de un serviciu excelent pentru clienți, garantând întotdeauna satisfacția maximă.',
    },
    contact: 'Contactați-ne',
  },
})

export default locales
